import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Layout, Section, Carousel } from 'components';
import { StaticImage } from 'gatsby-plugin-image';

const GardenPage = () => {
  const data = useStaticQuery(query);
  const imgList = data.strapiGarden.carousel_img;
  const pageDescription = data.strapiGarden.page_description;
  const box1 = {
    backgroundColor: '#FAF1E5',
    width: '33vw',
    height: '15vw',
    padding: '25px',
    position: 'absolute',
    top: '39%',
    left: '33%',
    zIndex: '-1',
  };

  return (
    <Layout>
      <Carousel
        imgList={imgList}
        pageTitle={'가든'}
        pageDescription={pageDescription}
      />

      <Section>
        <div className='uk-flex' style={{ position: 'relative' }}>
          <div
            className='uk-flex uk-flex-column'
            uk-scrollspy='cls: uk-animation-fade'
          >
            <div className='uk-h2 uk-text-bold'>
              설악산 아래 유럽식 정원을 만나다
            </div>
            <div className='uk-p'>
              설악산책의 작은 광장으로 나가면 설악산을 배경으로 둔 유럽식 정원을
              만날 수 있습니다. 규칙적이면서 깔끔한 포멀 가든에는 계절마다
              돋보이는 꽃들로 볼거리가 많습니다. 산책로와 벤치를 지나보면
              산수유, 앵두, 블루베리 등 열매나무들도 자라고 있습니다 따뜻한
              색감의 풍성한 초화와 울타리가 정원 전체를 아늑하게 감싸고
              있습니다.
            </div>
            <div className='uk-margin-large'>
              <StaticImage
                src='../images/garden/garden7.jpeg'
                alt='garden 7'
                imgStyle={{ maxWidth: '600px' }}
              />
            </div>
          </div>
          <div
            className='uk-visible@m'
            uk-scrollspy='cls: uk-animation-fade'
            style={box1}
          />
          <div className='uk-visible@m' uk-scrollspy='cls: uk-animation-fade'>
            <StaticImage
              src='../images/garden/garden6.jpeg'
              alt='A kitten'
              imgStyle={{ maxWidth: '450px', marginLeft: 'auto' }}
            />
          </div>
        </div>
      </Section>
    </Layout>
  );
};

const query = graphql`
  query {
    strapiGarden {
      carousel_img {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
      page_description
    }
  }
`;

export default GardenPage;
